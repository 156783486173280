<template>
  <div>
    <b-container>
      <b-row><h3 class="page-title">MEDIA ARCHIVE</h3></b-row>
      <b-row
        ><p class="page-subtitle">
          Here you can search and find all the previous events’ media
        </p></b-row
      >
    </b-container>
    <b-container class="archive-menu">
      <b-row class="text-center">
        <b-col
          ><router-link :to="`/${mainPath}/video`" class="menu-item" exact
            ><i class="fa fa-video menu-icon"></i>
            <p class="icon-label">VIDEOS</p></router-link
          ></b-col
        >
        <b-col>
          <router-link :to="`/${mainPath}/gallery`" class="menu-item" exact
            ><i class="fa fa-image menu-icon"></i>
            <p class="icon-label">GALLERIES</p></router-link
          ></b-col
        >
        <b-col
          ><router-link :to="`/${mainPath}/post-guide`" class="menu-item" exact
            ><i class="fa fa-newspaper-o menu-icon"></i>
            <p class="icon-label">POST EVENT GUIDE</p></router-link
          ></b-col
        >
      </b-row>
    </b-container>
    <keep-alive>
      <router-view :key="$route.fullPath" />
    </keep-alive>
  </div>
</template>
<script>
export default {
  name: 'MediaArchive',
  data() {
    return {

    };
  },
  computed: {
    mainPath() {
      return this.$route.path.includes('events-media-archive') ? 'events-media-archive' : 'media-archive';
    },
  },
};
</script>
<style scoped src="./MediaArchive.css"></style>
